require('./bootstrap');

// Error messages, warnings, success and info messages.
$(document).ready(function() {
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "10000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    moment.locale('pt-br');

    mensagens = $('div[name="messages"]');
    $(mensagens).find('div[name=error]').find('p').each(function() {
        toastr.error($(this).text());
    });
    $(mensagens).find('div[name=warning]').find('p').each(function() {
        toastr.warning($(this).text());
    });
    $(mensagens).find('div[name=success]').find('p').each(function() {
        toastr.success($(this).text());
    });
    $(mensagens).find('div[name=info]').find('p').each(function() {
        toastr.info($(this).text());
    });
    $(mensagens).remove();

    $("#pushmenu").click(function() {
        Cookies.set('sidebar-collapse', !$('body').hasClass('sidebar-collapse'));
    });

    if (eval(Cookies.get('sidebar-collapse')) && !$('body').hasClass('sidebar-collapse'))
        $('body').addClass('sidebar-collapse');
});
